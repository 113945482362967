@import '@/styles/_global';
.HearoCarousel__gradient {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
  background-position: bottom center;
  height: toRem(661);
  border-radius: toRem(16);
  position: relative;
  min-height: toRem(389);
}
.imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlayImage {
  top: 0;
  left: 0;
  z-index: 10; // Ensure it is on top of the background image
}

.bannerImg {
  object-fit: cover; // Or any other preferred fit for the background image
  object-position: center center;
}

.img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: bottom center;
  z-index: 0;
}

.HearoCarousel__content {
  width: 100%;
  padding: toRem(24);
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
}
.HearoCarousel__title {
  color: var(--P-Blue, #1a325d);
  font-family: var(--font-baskervville);
  font-size: toRem(32);
  font-weight: 400;
  line-height: toRem(35);
  letter-spacing: -3%; //toRemDesktop(-2.16);
  margin-bottom: toRem(22);
  max-width: 90%;
}

.program{
  font-size: toRem(16);
  margin: 0;
  font-weight: 700;
  color: #1a325d;
}

@include mobile{
  .HearoCarousel__title {
    margin-bottom: 0rem;
    max-width: 100%;
  }
}
.HearoCarousel__subtitle {
  color: var(--P-Blue, #1a325d);
  font-family: var(--font-raleway);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: toRem(24);
  font-weight: 700;
  line-height: toRem(30.5); /* 127.083% */
  letter-spacing: 5%;
  margin-bottom: toRem(24);
}
@include desktop() {
  .img {
    object-position: center center;
  }
  .HearoCarousel__gradient {
    border-radius: toRemDesktop(32);
    height: 450px;
    background-position: 50% 50%;
  }
  .HearoCarousel__gradient::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 14.17%,
      rgba(255, 255, 255, 0) 60%
    );
    z-index: 1;
  }
  .HearoCarousel__content {
    width: toRemDesktop(843);
    height: toRemDesktop(843);
    padding: toRemDesktop(50) toRemDesktop(55);
    flex-shrink: 0;
  }
  .HearoCarousel__title {
    font-size: toRemDesktop(72);
    line-height: toRemDesktop(82);
    margin-bottom: 0;
    max-width: 100%;
  }
  
  .HearoCarousel__subtitle {
    font-size: toRemDesktop(42);
    line-height: toRemDesktop(71);
    letter-spacing: 5%; //toRemDesktop(2.1);
    margin-bottom: toRemDesktop(40);
  }
  .HearoCarousel__cta {
    font-size: smaller;
  }
}
@include mobile{
  .HearoCarousel__gradient {
    height: 25rem;
    min-height: 25rem;
  }

  .lastItem {
    width: 12rem;
  }
  .secondBanner{
    max-width: 12rem;
  }
}
